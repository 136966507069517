import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Route, Router, RouterStateSnapshot } from "@angular/router";
import { AuthenticationService } from "../authentication.service";


@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate{
constructor(private router: Router, private authenticationService:AuthenticationService){}


canActivate(route: ActivatedRouteSnapshot,
   state: RouterStateSnapshot): boolean {

  const url: string = state.url;
  return this.checkLogin(url);
}

canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
  return this.canActivate(route, state);
}

canLoad(route: Route): boolean {

  const url = `/${route.path}`;
  return this.checkLogin(url);
}

checkLogin(url: string): boolean {

  if (this.authenticationService.isloggedIn) {
      return true;
  }
  this.authenticationService.loginRedirectUrl = url;
  this.router.navigate(['/'],{queryParams:{'redirectURL':url}});

  return false;
}

}
